import React from 'react';
import styled from 'styled-components';
import HubspotForm from 'src/lib/components/HubspotForm';
import Typography from 'components/Typography';
import Spacer from 'components/Spacer';
import { FormWrap } from 'components/formElements';
import defaultFormStyles from 'components/formElements/defaultFormStyles';
import { requireEnv } from 'src/helpers';
import { fieldDefinitions } from './fields';


export interface Props {
  formTitle?: string,
}

const ContactForm: React.FC<Props> = ({
  formTitle = 'Tell us all about it',
}) => {
  const contactFormGuid = requireEnv(process.env.GATSBY_HUBSPOT_CONTACT_FORM_GUID);
  const contactFormPortalId = requireEnv(process.env.GATSBY_HUBSPOT_CONTACT_FORM_PORTAL_ID);

  return (
    <ContactFormOuter>
      <FormWrap>
        <Typography variant="h3">{formTitle}</Typography>
        <Spacer mb={4} />
        <HubspotForm
          fieldDefinitions={fieldDefinitions}
          formGuid={contactFormGuid}
          portalId={contactFormPortalId}
          formName="contact-form"
          {...defaultFormStyles}
        />
      </FormWrap>
    </ContactFormOuter>
  );
};
export default ContactForm;

const ContactFormOuter = styled.div``;
